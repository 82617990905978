.control {
    --background: var(--form_background, var(--color-white));
    --color: var(--form_color, var(--color-black));
    --focus-color: var(--form_focus-color, var(--color-black));
    --border-color: var(--form_border-color, var(--color-white-darken-5));
    --label-color: var(--form_label-color, var(--color-black));
    --caret-color: var(--form_caret-color, var(--color));
    --border-radius: var(--form_border-radius, var(--global-border-radius));
    width: 100%;
    margin: var(--gap) auto calc(3 * var(--gap));
    position: relative;
}

.input,
.label {
    padding: calc(0.75 * var(--gap)) var(--gap);
}

.input {
    width: 100%;
    overflow: hidden;
    background: hsl(var(--background));
    color: hsl(var(--color));
    caret-color: hsl(var(--caret-color));
    border-radius: var(--border-radius);
    border: 1px solid hsla(var(--border-color), 0.5);

    &:not(.disabled):focus {
        box-shadow: 0 0 0 3px hsla(var(--focus-color), 0.25);
    }

    &:focus,
    &[placeholder] {

        + .label {

            .labelContent {
                transform: translate3d(0, -2.5em, 0) scale3d(0.9, 0.9, 1);
            }
        }
    }

    &:focus {
        border-radius: var(--border-radius);
    }

    &::selection {
        background-color: hsl(var(--variant, var(--color-black)));
        color: hsl(var(--color-white));
        transition: background-color var(--transition-out) var(--transition-timing);
    }

    &::placeholder {
        color: hsla(var(--color), 0.5);
        font-size: 75%;
    }

    &[readonly] {
        color: hsla(var(--color), 0.75);
        border-radius: var(--border-radius);
        border: 1px dashed hsla(var(--color), 0.25);
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.hasMaxlength {

    .input {
        padding-right: 90px;
    }
}

.nonempty {

    + .label {

        .labelContent {
            color: hsl(var(--label-color));
            transform: translate3d(0, -2.5em, 0) scale3d(0.9, 0.9, 1);
        }
    }
}

.label {
    width: auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    font-weight: 700;
    border: 1px solid transparent;
}

.labelContent {
    display: inline-flex;
    transform-origin: left center;
    transition-property: transform, color;
    transition-duration: var(--transition-in);
    transition-timing-function: var(--ease-out-back);
}

.required {
    position: absolute;
    top: calc(0.6 * var(--gap));
    left: calc(0.4 * var(--gap));
    color: hsl(var(--global_danger-color, var(--color-red)));
    font-size: var(--font-size-00);
}

.maxlength {
    padding: 0 var(--gap);
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 1px;
    background: hsl(var(--background));
    color: hsl(var(--color));
    font-size: var(--font-size-000);
    transform: translateY(-50%);
}

.success {
    --variant: var(--global_success-color, var(--color-green));
}

.info {
    --variant: var(--global_info-color, var(--color-blue));
}

.warning {
    --variant: var(--global_warning-color, var(--color-yellow));
}

.danger {
    --variant: var(--global_danger-color, var(--color-red));
}

.success,
.info,
.warning,
.danger {

    &,
    .input:focus,
    .input:hover {
        --focus-color: var(--variant);
        --border-color: var(--variant);
        --label-color: var(--variant);
        --caret-color: var(--variant);
    }
}
